import { faPrescription, faUserEdit } from "@fortawesome/free-solid-svg-icons"

export const flexmedReimburseStep = [
  {
    pathname: "flexmed/reimburse/upload",
    label: "Prescription and Receipt Upload",
    icon: faPrescription,
  },
  {
    pathname: "flexmed/reimburse/employee-information",
    label: "Employee Information",
    icon: faUserEdit,
  },
]
