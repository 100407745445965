import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required"
const VALID_EMAIL = "Please input a valid email"
const VALID_NUMBER = "Please input a valid number"
const VALID_MOBILE_NUMBER =
  "Please input a valid mobile number in this format: 09991234567"
const VALID_EMPLOYEE_ID =
  "Please input a valid Employee ID Number in this format: 1234567"
const VALID_BANK_ACCOUNT_NUMBER =
  "Please input a valid bank account number in this format: 001234567891"
const VALID_MAXICARE_CARD_NUMBER =
  "Please input a valid card number in this format: 1234 5678 9876 5432"
const VALID_BLOOD_PRESSURE =
  "Please input a valid blood pressure in this format: 120/80"
const VALID_DOCTOR_PRC_NUMBER =
  "Please input a valid PRC license number in this format: 1234567"
const VALID_MGDL_NUMBER = "Please input a valid mg/dL value"
const VALID_MMOL_NUMBER = "Please input a valid mmol/L value"
const VALID_WEIGHT = "Please input a valid weight"

export const getDefaultValidation = ({ field }) => {
  let fieldValidation
  switch (field.type) {
    case "checkbox":
      fieldValidation = Yup.array()
      break

    case "schedule":
      fieldValidation = Yup.object()

      for (let objectField of field.fieldNames) {
        fieldValidation = fieldValidation.concat(
          fieldValidation.shape({
            [objectField]: Yup.string().required(REQUIRED_MESSAGE),
          })
        )
      }

      break

    case "select":
      fieldValidation = Yup.object().shape({
        value: Yup.string().when("label", {
          is: () => !!field.required,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
      })
      break

    case "multiselect":
      fieldValidation = Yup.array()
        .of(
          Yup.object().shape({
            value: Yup.string().when("label", {
              is: () => !!field.required,
              then: Yup.string().required(REQUIRED_MESSAGE),
              otherwise: Yup.string(),
            }),
          })
        )
        .min(field?.min || 1, REQUIRED_MESSAGE)
      break

    case "date":
      fieldValidation = Yup.object()
      break

    case "address":
      let shouldInputSite = field.fieldNames.includes(`${field.name}.siteName`)

      fieldValidation = Yup.object().shape({
        city: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        province: Yup.object().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.object().shape({
            value: Yup.string().required(REQUIRED_MESSAGE),
          }),
          otherwise: Yup.object().shape({
            value: Yup.string(),
          }),
        }),
        streetAddress: Yup.string().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        houseNumber: Yup.string().when("addressType", {
          is: (addressType) =>
            !!field.required &&
            (!shouldInputSite || (shouldInputSite && addressType === "Home")),
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        addressType: Yup.string().when("value", {
          is: () => !!field.required,
          then: Yup.string().required(REQUIRED_MESSAGE),
          otherwise: Yup.string(),
        }),
        notes: Yup.string(),
      })
      break

    case "number":
      fieldValidation = Yup.number()
      if (field?.min > 0) {
        fieldValidation = fieldValidation.concat(
          fieldValidation.min(
            field?.min,
            `Please input a number not less than ${field?.min}`
          )
        )
      }

      if (field?.max > 0) {
        fieldValidation = fieldValidation.concat(
          fieldValidation.max(
            field?.max,
            `Please input a number not higher than ${field?.max}`
          )
        )
      }

      if (field?.regex === "weight") {
        fieldValidation = fieldValidation.concat(
          fieldValidation.matches(
            /^\d+(\.\d{1,2})?$/,
            "Please input a valid weight"
          )
        )
      }
      break

    case "text":
    case "radio":
    case "textarea":
    default:
      fieldValidation = Yup.string()
        .trim()
        .matches(/.*\S.*/, REQUIRED_MESSAGE)

      if (field?.validation?.includes("Email"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.email(VALID_EMAIL)
        )

      if (field?.validation?.includes("Personal Email"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.notOneOf(
            [Yup.ref("email")],
            "Personal email should not be the same with work email"
          )
        )

      if (field?.validation?.includes("Mobile Number"))
        fieldValidation = fieldValidation
          .concat(
            fieldValidation
              .min(11, VALID_MOBILE_NUMBER)
              .max(11, VALID_MOBILE_NUMBER)
              .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
          )
          .trim()

      if (field?.validation?.includes("Weight"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.matches(/^\d+(\.\d{1,2})?$/, VALID_WEIGHT)
        )

      // if (field?.validation?.includes("Employee ID"))
      //   fieldValidation = fieldValidation.concat(
      //     fieldValidation.matches(/^[0-9]{1,7}$/, VALID_EMPLOYEE_ID)
      //   )
      if (field?.validation?.includes("Blood Pressure"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.matches(/^[0-9]{2,3}\/[0-9]{2,3}$/, VALID_BLOOD_PRESSURE)
        )

      if (field?.validation?.includes("Number"))
        fieldValidation = fieldValidation
          .concat(fieldValidation.matches(/^(\d*\.)?\d+$/, VALID_NUMBER))
          .trim()

      if (field?.validation?.includes("PRC Number"))
        fieldValidation = fieldValidation.concat(
          fieldValidation.matches(/^[0-9]{7}$/, VALID_DOCTOR_PRC_NUMBER)
        )

      if (field?.validation?.includes("Bank Account Number"))
        fieldValidation = fieldValidation
          .concat(
            fieldValidation
              .min(12, VALID_BANK_ACCOUNT_NUMBER)
              .max(12, VALID_BANK_ACCOUNT_NUMBER)
              .matches(/^[0-9]{1,12}$/, VALID_BANK_ACCOUNT_NUMBER)
          )
          .trim()

      if (field?.validation?.includes("Maxicare Card Number"))
        fieldValidation = fieldValidation.concat(
          fieldValidation
            .min(19, VALID_MAXICARE_CARD_NUMBER)
            .max(19, VALID_MAXICARE_CARD_NUMBER)
            .matches(
              /[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/,
              VALID_MAXICARE_CARD_NUMBER
            )
        )
      
        if (field?.name === "viberNumber")
          fieldValidation = fieldValidation
          .concat(
            fieldValidation
              .min(11, VALID_MOBILE_NUMBER)
              .max(11, VALID_MOBILE_NUMBER)
              .matches(/^09[0-9]{9}$/, VALID_MOBILE_NUMBER)
          )
          .trim()

      if (
        field?.label?.includes("Result") &&
        field?.referenceAnswer?.includes("FBS")
      ) {
        fieldValidation = Yup.number()
          .when("fbsUnitOfMeasure", {
            is: "mg/dL",
            then: Yup.number().concat(
              Yup.number()
                .min(0, VALID_MGDL_NUMBER)
                .max(999, VALID_MGDL_NUMBER)
                .typeError("This field must be a number")
            ),
          })
          .when("fbsUnitOfMeasure", {
            is: "mmol/L",
            then: Yup.number().concat(
              Yup.number()
                .min(0, VALID_MMOL_NUMBER)
                .max(10, VALID_MMOL_NUMBER)
                .typeError("This field must be a number")
            ),
          })
          .typeError("This field must be a number")
      }
      if (
        field?.label?.includes("Result") &&
        field?.referenceAnswer?.includes("LDL Cholesterol")
      ) {
        fieldValidation = Yup.number()
          .when("ldlUnitOfMeasure", {
            is: "mg/dL",
            then: Yup.number().concat(
              Yup.number()
                .min(0, VALID_MGDL_NUMBER)
                .max(999, VALID_MGDL_NUMBER)
                .typeError("This field must be a number")
            ),
          })
          .when("ldlUnitOfMeasure", {
            is: "mmol/L",
            then: Yup.number().concat(
              Yup.number()
                .min(0, VALID_MMOL_NUMBER)
                .max(10, VALID_MMOL_NUMBER)
                .typeError("This field must be a number")
            ),
          })
          .typeError("This field must be a number")
      }

      break
  }

  return fieldValidation
}

export const getCustomValidation = ({ field, fieldValidation }) => {
  if (!!field.required) {
    if (!!field.referenceAnswer) {
      let referenceQuestion = field.referenceQuestionName[0]

      if (field?.type === "date")
        fieldValidation = fieldValidation.concat(
          fieldValidation.when(referenceQuestion, {
            is: (referenceAnswer) =>
              referenceAnswer === field?.referenceAnswer ||
              referenceAnswer?.includes(field?.referenceAnswer),
            then: fieldValidation.shape({
              month: Yup.object().shape({
                value: Yup.string().when("label", {
                  is: () => !!field.required,
                  then: Yup.string().required(REQUIRED_MESSAGE),
                  otherwise: Yup.string(),
                }),
              }),
              date: Yup.object().shape({
                value: Yup.string().when("label", {
                  is: () => !!field.required,
                  then: Yup.string().required(REQUIRED_MESSAGE),
                  otherwise: Yup.string(),
                }),
              }),
              year: Yup.object().shape({
                value: Yup.string().when("label", {
                  is: () => !!field.required,
                  then: Yup.string().required(REQUIRED_MESSAGE),
                  otherwise: Yup.string(),
                }),
              }),
            }),
          })
        )
      fieldValidation = fieldValidation.concat(
        fieldValidation.when(referenceQuestion, {
          is: (referenceAnswer) =>
            referenceAnswer === field?.referenceAnswer ||
            referenceAnswer?.includes(field?.referenceAnswer),
          then: fieldValidation.required(REQUIRED_MESSAGE),
        })
      )
    } else {
      if (field?.type === "date")
        fieldValidation = fieldValidation.concat(
          fieldValidation.shape({
            month: Yup.object().shape({
              value: Yup.string().when("label", {
                is: () => !!field.required,
                then: Yup.string().required(REQUIRED_MESSAGE),
                otherwise: Yup.string(),
              }),
            }),
            date: Yup.object().shape({
              value: Yup.string().when("label", {
                is: () => !!field.required,
                then: Yup.string().required(REQUIRED_MESSAGE),
                otherwise: Yup.string(),
              }),
            }),
            year: Yup.object().shape({
              value: Yup.string().when("label", {
                is: () => !!field.required,
                then: Yup.string().required(REQUIRED_MESSAGE),
                otherwise: Yup.string(),
              }),
            }),
          })
        )
      else
        fieldValidation = fieldValidation.concat(
          fieldValidation.required(REQUIRED_MESSAGE)
        )
    }

    if (!!field.minLength) {
      fieldValidation = fieldValidation.concat(
        fieldValidation.min(field.minLength, REQUIRED_MESSAGE)
      )
    }
  }

  return fieldValidation
}
