import React, { useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import ButtonGroup from "../../../Elements/ButtonGroup"

import { generateFormField } from "../../../Elements/Form/services/form"
import { formSchema } from "../utils/formSchema"
import { flexmedReimburseStep } from "../../Reimburse/utils/flexmedReimburseSteps"
import { FlexmedReimburseContext } from "../../FlexmedContext/FlexmedReimburseContext"

let { parseFormField } = require("../../../../services/airtable")

const FlexmedReimburseEmployeeInformation = ({ pageContext, location }) => {
  // state and dispatch from flexmed cashless context
  const { flexmedReimburseState, flexmedReimburseDispatch } = useContext(
    FlexmedReimburseContext
  )
  // form fields, validation, and parsing form fields to be sections
  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSubmitReimburseEmployeeInformation = (values) => {
    flexmedReimburseDispatch({
      type: "SAVE_EMPLOYEE_INFORMATION",
      payload: {
        ...values,
      },
    })

    flexmedReimburseDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...flexmedReimburseState,
        ...values,
      },
    })
    navigate(pageContext.nextPath)
  }

  const CURRENT_STEP = 3
  const TOTAL_STEPS = 3

  useEffect(() => {
    flexmedReimburseDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      steps={flexmedReimburseStep}
      location={location}
      currentStep={CURRENT_STEP}
      totalSteps={TOTAL_STEPS}
      path={pageContext?.module?.path}
      hasMobileStepper={true}
    >
      <Container isCentered>
        <Formik
          enableReinitialize
          initialValues={{ ...flexmedReimburseState }}
          onSubmit={handleSubmitReimburseEmployeeInformation}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  className="mt-1 mb-3"
                >
                  {section?.message && (
                    <Message color="light">{section?.message}</Message>
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            formFields: section?.fields,
                            formField: field,
                            values: values,
                            setFieldValue,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}
              <ButtonGroup
                back={{
                  link: pageContext.backPath,
                }}
                next={{
                  label: `Next: ${pageContext.module.next}`,
                  link: pageContext.nextPath,
                  type: "submit",
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default FlexmedReimburseEmployeeInformation
