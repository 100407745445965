import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * @param {string} title string
 * @param {} children
 */

const Section = ({ title, children, addOns, className, id, isRequired }) => {
  return (
    <Fragment>
      {title && (
        <div className={classNames(styles["section"])} id={id}>
          {addOns?.left ? <span>{addOns?.left}</span> : null}
          <h3 className="has-text-primary mt-0">
            {title}{" "}
            {/* {isRequired === null && (
              <span className="is-italic has-text-weight-normal has-text-grey">
                (Optional)
              </span>
            )} */}
          </h3>
          {addOns?.right}
        </div>
      )}
      <div className={className || "mt-1 mb-3"}>{children}</div>
    </Fragment>
  )
}

export const FmrSection = ({ title, children, addOns, className }) => (
  <section className="mb-3">
    {title && (
      <header className={classNames(styles["fmrSection"])}>
        {addOns?.left ? <span>{addOns?.left}</span> : null}
        <div className={classNames(styles["fmrSectionBody"])}>
          <h3 className="has-text-primary mt-1">{title}</h3>
          {addOns?.right}
        </div>
      </header>
    )}

    <div className={className || "mt-1 mb-2"}>{children}</div>
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Section
