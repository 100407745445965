import React from "react"
import { navigate } from "gatsby"

import Button from "./Button"

import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/**
 * Add a wrapper to a group of buttons
 * @param {object} next options for next
 * @param {object} back options for back
 */

const ButtonGroup = ({ next, back }) => {
  const handlePressBack = () => {
    if (back.callback) {
      back.callback()
      return
    }
    navigate(back.link, {
      state: { ...(back.state || {}) },
    })
  }

  const handlePressNext = () => {
    if (next.type !== "submit") {
      if (next.callback) {
        next.callback()
        return
      }
      navigate(next.link)
    }
  }

  return (
    <div className="columns px-7 px-0-mobile mt-3 is-flex">
      {back && (
        <div className="column is-narrow is-narrow-mobile">
          <Button
            type="button"
            className="is-outlined action-button"
            size="medium"
            to={back?.link}
            color="orange"
            value="back"
            onClick={handlePressBack}
            aria-label={back?.label || "back button"}
          >
            {back?.label || (
              <span>
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  size="lg"
                  className="mx-1"
                />
              </span>
            )}
          </Button>
        </div>
      )}

      <div className="column">
        <Button
          type={next?.type || "submit"}
          size="medium"
          className="is-fullwidth action-button"
          color="orange"
          onClick={handlePressNext}
          isLoading={next?.loading}
          isDisabled={next?.disabled}
        >
          <span className="is-hidden-mobile">{next?.label || "Next"}</span>
          <span className="is-hidden-tablet-only is-hidden-desktop">
            {"Next"}
          </span>
        </Button>
      </div>
    </div>
  )
}

export default ButtonGroup
